import "./Footer.scss";
import SocialIcons from "../SocialIcons/SocialIcons";
import logo from "../../image/logotype/logo.svg";
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <footer className="footer footer__container">
            <div className="contain__block">
                <div>
                    <div className="footer__img">
                        <Link to="/">
                            <img className="TM" src={logo} alt="logo" />
                        </Link>
                        <Link to="/">
                            <div className="header__logo-title">
                                <span className="footer__text-under">САНБЕРРІ УКРАЇНА</span>
                            </div>
                        </Link>
                    </div>
                    <div className="contain__mail">
                        <Link to="privacy"><p className="navigation__link navigation__link-privacy navigation__link--color">Політика конфіденційності</p></Link>
                        <p className="copywriting__title">2014-2025 © Санберрі Україна. Всі права захищені.</p>
                    </div>
                </div>
                <div>
                    <div className="contain__navigation--position">
                        <Link to="catalog">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Продукція</span>
                        </Link>
                        <Link to="catalogSeed">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Насіння</span>
                        </Link>
                        <Link to="culture">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Культура</span>
                        </Link>
                        <Link to="blog">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Блог</span>
                        </Link>
                        <Link to="innovation">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Інновації</span>
                        </Link>
                        <Link to="distributors">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Дистриб'юція</span>
                        </Link>
                        <Link to="partners">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Партнери</span>
                        </Link>
                        <Link to="contact">
                            <span className="navigation__link navigation__link-inner navigation__link--color">Контакти</span>
                        </Link>
                    </div>
                </div>
                <div>
                    <div className="contain__navigation--position">
                        <Link to="catalog">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Продукція</span>
                        </Link>
                        <Link to="catalogSeed">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Насіння</span>
                        </Link>
                        <Link to="culture">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Культура</span>
                        </Link>
                        <Link to="blog">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Блог</span>
                        </Link>
                        <Link to="innovation">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Інновації</span>
                        </Link>
                        <Link to="distributors">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Дистриб'юція</span>
                        </Link>
                        <Link to="partners">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Партнери</span>
                        </Link>
                        <Link to="contact">
                            <span className="navigation__link navigation__link-grid navigation__link--color">Контакти</span>
                        </Link>
                        <h2 className="contain__subtitle">Каталог товарів</h2>
                        <Link className="navigation__link navigation__link-weight navigation__link--color" to="agrobolikCard">
                            <span>Добрива для позакореневого живлення</span>
                        </Link>
                        <Link className="navigation__link navigation__link-weight navigation__link--color" to="agroteinCard">
                            <span>Гранульовані NPK</span>
                        </Link>
                        <Link className="navigation__link navigation__link-weight navigation__link--color" to="NPKCard">
                            <span>Рідкі NPK</span>
                        </Link>
                        <Link className="navigation__link navigation__link-weight navigation__link--color" to="sunflowerCard">
                            <span>Насіння соняшнику</span>
                        </Link>
                        <Link className="navigation__link navigation__link-weight navigation__link--color" to="cornCard">
                            <span>Насіння кукурудзу</span>
                        </Link>
                    </div>
                </div>
                <div>
                    <div className="contain__navigation--position">
                        <h2 className="contain__subtitle">Контакти</h2>
                        <span className="navigation__link navigation__link-weight navigation__link--color">м. Вінниця, вулиця Сергія Зулінського, 43</span>
                        <a className="navigation__link navigation__link-weight navigation__link--color" href="tel:+380683699030">+38(068)-369-90-30</a>
                        <a className="navigation__link navigation__link-weight navigation__link--color" href="mailto:sunberry.ukr@gmail.com"> sunberry.ukr@gmail.com</a>
                    </div>
                </div>
                <div className="footer__right">
                    <h2 className="contain__subtitle">Зв’язатися з нами</h2>
                    <div className="footer__icons">
                        <SocialIcons />
                    </div>
                    <div className="contain__time time">
                        <span className="time__work">Графік роботи:</span>
                        <div className="contain__work">
                            <div className="contain__work-time">
                                <span>Пн-Пт:</span>
                                <span>09:00–18:00</span>
                            </div>
                            <div className="contain__work-time">
                                <span>Сб-Нд:</span>
                                <span>вихідний</span>
                            </div>
                        </div>
                    </div>
                    <div className="contain__privacy">
                        <Link to="privacy"><p className="navigation__link navigation__link-privacy navigation__link--color">Політика конфіденційності</p></Link>
                        <p className="copywriting__title">2014-2025 © Санберрі Україна. Всі права захищені.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
